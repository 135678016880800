import { DateTime } from 'luxon';
import { builder } from '@builder.io/react';
import envConfig from '@/helpers/config';
import { APPLICATION_CONFIG } from '@/helpers/builder/models';

export type ApplicationConfigProps = {
  fetched?: number,
  fetchedFrom?: 'client' | 'server',
  env?: string,
  copyrightYear?: number,
  linkedInApiVersion?: string,
  profile?: {
    audienceList?: string,
    computedTraitList?: string,
    navigationEventsList?: string,
  },
};

const nonEnvironmentSpecificAppConfigDefaults = {
  copyrightYear: (new Date()).getFullYear(),
  linkedInApiVersion: '202501',
  profile: {
    audienceList: 'tech_nudge_mobile,registered_and_not_first_course_completed',
    computedTraitList: 'last_late_assignment_course_offering_id,last_late_assignment_source_id',
    navigationEventsList: 'Page Viewed,MyPhoenixNavEvent',
  },
};

const getAppConfigDefaultsByEnv = () => {
  switch (process.env.NEXT_PUBLIC_RUNTIME_ENV) {
    case 'production': // .env.production
      return {
        ...nonEnvironmentSpecificAppConfigDefaults,
        env: 'production',
      };
    case 'qa': // .env.qa
      return {
        ...nonEnvironmentSpecificAppConfigDefaults,
        env: 'qa',
      };
    default: // 'stage' (.env.test) or local (.env.local)
      return {
        ...nonEnvironmentSpecificAppConfigDefaults,
        env: 'stage',
      };
  }
};

const ApplicationConfigDefaults: ApplicationConfigProps = getAppConfigDefaultsByEnv();

const { Builder } = envConfig;
const unfetchedAppConfig: ApplicationConfigProps = {
  fetched: 0,
  ...ApplicationConfigDefaults,
};
let appConfig: ApplicationConfigProps = unfetchedAppConfig;

const getClientOrServer = () => ((typeof window === 'undefined') ? 'server' : 'client');

const isExpired = (config: ApplicationConfigProps) => {
  if (!config.fetchedFrom) {
    return true;
  }
  if (getClientOrServer() === config.fetchedFrom) {
    return DateTime.now().diff(
      DateTime.fromMillis(config.fetched),
    ).as('seconds') >= Builder.appConfigCacheSecs;
  }
  return false;
};

const clearApplicationConfig = () => {
  appConfig = unfetchedAppConfig;
};

const getApplicationConfigAsync = async () => {
  if (isExpired(appConfig)) {
    const response = await builder.get(
      APPLICATION_CONFIG,
      {
        userAttributes: { },
        cachebust: true,
      },
    ).promise().catch(
      (error) => {
        // eslint-disable-next-line no-console
        console.error('Error on builder.get(APPLICATION_CONFIG... \n', error);// TODO: monitor this
      },
    );
    if (response?.data) {
      appConfig = {
        fetched: DateTime.now().toMillis(),
        fetchedFrom: getClientOrServer(),
        ...ApplicationConfigDefaults,
        ...response.data,
      };
    } else {
      appConfig = {
        ...unfetchedAppConfig,
      };
    }
  }
  return appConfig;
};

const getApplicationConfig = () => {
  if (isExpired(appConfig)) {
    getApplicationConfigAsync();
  }
  return appConfig;
};

const setApplicationConfig = (config: ApplicationConfigProps) => {
  appConfig = config || unfetchedAppConfig;
};

export {
  ApplicationConfigDefaults,
  clearApplicationConfig,
  getApplicationConfigAsync,
  getApplicationConfig,
  setApplicationConfig,
};
